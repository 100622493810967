import styled from 'styled-components'
import { motion } from 'framer-motion'

export const About = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 4rem 2rem 1rem 2rem;
	text-align: center;
	color: white;
	min-height: 90vh;
	margin-top: 10vh;

  	@media (min-width: 1024px) {
		  padding-top: 8rem;
	  }
  	@media (min-width: 1200px) {
		max-height: 80vh; 
  		display: flex;
  		align-items: center;
  		justify-content: space-between;
  		padding: 10rem 10rem 0 10rem;
  	}
  	@media (min-width: 1550px) {
		  padding: 13rem 16rem 0 16rem;
	  }
`
export const Description = styled.div`
	padding: 0;
	display: flex;
	flex-direction: column;
	button {
		margin: 2rem 0rem 5rem 0rem;
	}
	span {
		text-shadow: 2px 2px 4px #000000;
	}
	@media (min-width: 768px) {
		p {
			font-size: 1.6rem;
		}
	}
	@media (min-width: 1024px) {
		max-width: 60%;
		padding-left: 2rem;
		padding-right: 6rem;
	}
	@media (min-width: 1200px) {
		flex: 1;
		padding-right: 5rem;
		font-weight: lighter;
	}
`
export const Image = styled.div`
	flex: 1;
	overflow: hidden;
	img {
		width: 100%;
		object-fit: scale-down;
	}
	@media (min-width: 540px) {
		height: 60vh;
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	@media (min-width: 768px) {
		height: 70vh;
		max-width: 60%;
		img {
			margin-top: 2rem;
		}
	}
	@media (min-width: 1024px) {
		max-width: 35%;
	}
	@media (min-width: 1400px) {
		max-width: 500px;
		padding-left: 7%;
		img {
			margin-top: 0;
		}
	}
`

export const Hide = styled.div`overflow: hidden;`
