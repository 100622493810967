import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { About, Description, Image, Hide } from '../styles'
//Framer Motion
import { motion } from 'framer-motion'
import { titleAnim, fade, photoAnim } from '../animation'
import Wave from './Wave'

const AboutSection = () => {
	return (
		<About className='about'>
			<Content className='Content'>
				<Description className='description'>
					<motion.div className='about-motion-div'>
						<Hide className='hide'>
							<motion.h2 variants={titleAnim} className='about-motion-h2'>
								Let's
							</motion.h2>
						</Hide>
						<Hide className='hide'>
							<motion.h2 variants={titleAnim} className='about-motion-h2'>
								<span>create</span> something special
							</motion.h2>
						</Hide>
						<Hide className='hide'>
							<motion.h2 variants={titleAnim} className='about-motion-h2'>
								together
							</motion.h2>
						</Hide>
					</motion.div>
					<motion.p variants={fade} className='about-motion-p'>
						Take a look a look at what I've been working on, and get in
						touch
					</motion.p>
					<Link id='contact' to='/contact'>
						<motion.button variants={fade}>Contact Me</motion.button>
					</Link>
				</Description>
				<Image className='image'>
					<motion.img
						variants={photoAnim}
						src='https://firebasestorage.googleapis.com/v0/b/portfolio-3dacc.appspot.com/o/smileLighter.png?alt=media&token=5234587f-2e55-4e72-a157-c7c91cbb11c0'
						alt='portrait of developer'
						className='img'
					/>
				</Image>
			</Content>
			<Wave className='wave' />
		</About>
	)
}

const Content = styled(motion.div)`
  	@media (min-width: 1024px) {
		  display: flex;
		  flex-direction: row; 
		  justify-content: center;
		  /* align-items: center; */
	  }
`

export default AboutSection
