import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

//Animations
import { motion } from 'framer-motion'
import { pageAnimation, fade, photoAnim, lineAnim } from '../animation'
import { useScroll } from '../useScroll'
import ScrollTop from '../components/ScrollTop'

const ProjectListScreen = () => {
	const [ element1, controls1 ] = useScroll()
	const [ element2, controls2 ] = useScroll()
	const [ element3, controls3 ] = useScroll()
	const [ element4, controls4 ] = useScroll()

	return (
		<WhiteSpace>
			<Work
				style={{ background: '#fff' }}
				exit='exit'
				variants={pageAnimation}
				initial='hidden'
				animate='show'
			>
				<ScrollTop />
				<Project
					ref={element1}
					variants={fade}
					animate={controls1}
					initial='hidden'
				>
					<Link to='/work/e-commerce'>
						<motion.h2 variants={fade}>E-Commerce</motion.h2>
					</Link>
					<motion.div variants={lineAnim} className='line' />
					<Link to='/work/e-commerce'>
						<motion.img
							className='shunami-gif'
							variants={photoAnim}
							src='https://firebasestorage.googleapis.com/v0/b/portfolio-3dacc.appspot.com/o/shunamiHome.png?alt=media&token=7bc2e4e5-0d11-493a-8833-70fbf98acc62'
							alt='home page'
						/>
					</Link>
				</Project>

				<Project
					ref={element2}
					variants={fade}
					animate={controls2}
					initial='hidden'
				>
					<Link to='/work/fitfocus'>
						<motion.h2 variants={fade}>
							FitFocus - Productivity App
						</motion.h2>
					</Link>
					<motion.div variants={lineAnim} className='line' />
					<Link to='/work/fitfocus'>
						<motion.img
							variants={photoAnim}
							src='https://firebasestorage.googleapis.com/v0/b/portfolio-3dacc.appspot.com/o/fitFocus.png?alt=media&token=602787b0-64c6-471f-a172-2b43816e4024'
							alt='fitFocus illustration'
						/>
					</Link>
				</Project>

				<Project
					ref={element3}
					variants={fade}
					animate={controls3}
					initial='hidden'
				>
					<Link to='/work/blogcms'>
						<motion.h2 variants={fade}>Blog CMS</motion.h2>
					</Link>
					<motion.div variants={lineAnim} className='line' />
					<Link to='/work/blogcms'>
						<motion.img
							variants={photoAnim}
							src='https://firebasestorage.googleapis.com/v0/b/portfolio-3dacc.appspot.com/o/blogCover.png?alt=media&token=cdc1c83f-0ea8-43b2-b531-b4b04140e326'
							alt='blog cms'
						/>
					</Link>
				</Project>

				<Project
					ref={element4}
					variants={fade}
					animate={controls4}
					initial='hidden'
				>
					<Link to='/work/recipeapi'>
						<motion.h2 variants={fade}>Recipes API</motion.h2>
					</Link>
					<motion.div variants={lineAnim} className='line' />
					<Link to='/work/recipeapi'>
						<motion.img
							variants={photoAnim}
							src='https://firebasestorage.googleapis.com/v0/b/portfolio-3dacc.appspot.com/o/recipeDBCover.png?alt=media&token=2fed3560-1e44-4dfe-b01e-86dc38f3ede1'
							alt='recipe api'
						/>
					</Link>
				</Project>
			</Work>
		</WhiteSpace>
	)
}
const WhiteSpace = styled.div`
	min-height: 100vh;
	min-width: 100vw;
	background: white;
`
const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 4rem 2rem 2rem 2rem;
  margin-top: 10vh;
  h2 {
    padding: 1rem 0rem;
  }
	@media (min-width: 1300px) {
		margin-top: 8vh;
		padding-top: 5rem; 
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}

`
const Project = styled(motion.div)`
  padding-bottom: 10rem;
  .line {
    height: 0.5rem;
    background: #0B4C5F;
    margin-bottom: 3rem;
  }
  img {
	  width: 85%;
	  object-fit: cover;

  }
  img:hover {
	  transition: all 0.5s ease;
	  width: 90%;
  }
  a {
	  text-decoration: none;
	  color: #1b1b1b;
  }
  a:hover {
	  color:#0B4C5F; 
  }
`
export default ProjectListScreen
