import React from 'react'
import styled from 'styled-components'
import { Link as ReactRouterDomLink, useLocation } from 'react-router-dom'

const Link = ({ isActive, children, ...props }) => {
	return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>
}

export default function Header() {
	const { pathname } = useLocation()

	return (
		<HeaderWrapper>
			<StyledLink id='logo' to='/'>
				Catherine Alexandra
			</StyledLink>
			<Menu>
				<StyledLink
					style={{ color: pathname === '/' ? '#0b4c5f' : '#b8b7ad' }}
					to='/'
					isActive={pathname === '/'}
				>
					About
				</StyledLink>
				<StyledLink
					style={{ color: pathname === '/work' ? '#0b4c5f' : '#b8b7ad' }}
					to='/work'
					isActive={pathname === '/work'}
				>
					Projects
				</StyledLink>
				<StyledLink
					style={{
						color : pathname === '/resume' ? '#0b4c5f' : '#b8b7ad'
					}}
					to='/resume'
					isActive={pathname === '/resume'}
				>
					Resume
				</StyledLink>
				<StyledLink
					style={{
						color : pathname === '/contact' ? '#0b4c5f' : '#b8b7ad'
					}}
					to='/contact'
					isActive={pathname === '/contact'}
				>
					Contact
				</StyledLink>
			</Menu>
		</HeaderWrapper>
	)
}

const HeaderWrapper = styled.header`
	z-index: 3;
	height: 60px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0px;
	background-color: #282828;
	#logo {
		color: #b8b7ad;
		margin: 10px 0px 5px 8px;
		font-size: 1.3rem;
		height: 20px;
		padding: 5px 0px 5px 8px;
		align-self: flex-start;
	}
	@media (min-width: 540px) {
		flex-direction: row;
		height: 50px;
		#logo {
			font-size: 1.4rem;
			margin: auto auto auto 12px;
			padding: 0;
		}
	}
	@media (min-width: 768px) {
		position: fixed;
		height: 8%;
		#logo {
			font-size: 1.5rem;
			margin: auto 0 auto 20px;
			padding: 0;
		}
	}
`

const Menu = styled.nav`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	font-family: 'Roboto', sans-serif;
	font-size: .8em;
	position: fixed;
	width: 100%;
	top: 30px;
	left: 0;
	padding: 8px;
	box-sizing: border-box;
	background-color: #282828;
	height: 40px;
	z-index: 10;
	@media (min-width: 540px) {
		display: flex;
		left: 0;
		top: 0;
		padding: 0px;
		margin: auto 15px auto auto;
		width: initial;
		border-bottom: none;
		position: relative;
	}
	@media (min-width: 1024px) {
		margin-right: 2%;
	}
`

export const StyledLink = styled(Link)`
    padding: 8px 12px 8px 0px;
    margin: auto 0;
    text-decoration: none;
	&:hover {
		color: #E9FA03 !important;
	}
	&:active {
		color: #b8b7ad;
	}
	@media (min-width: 540px) {
		font-size: 1.3rem;
	}
	@media (min-width: 1024px) {
		font-size: 1.4rem;
		padding-right: 20px;
		font-weight: lighter;
	}

`
