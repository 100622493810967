import React from 'react'
import ScrollTop from '../components/ScrollTop'
import { Image } from '../styles'

//Animations
import { motion } from 'framer-motion'
import { pageAnimation, titleAnim } from '../animation'
import styled from 'styled-components'

const ResumeScreen = () => {
	return (
		<ResumeStyle
			exit='exit'
			variants={pageAnimation}
			initial='hidden'
			animate='show'
			style={{ background: '#1b1b1b' }}
		>
			<ScrollTop />
			<Hide>
				<motion.div variants={titleAnim}>
					<a
						href='https://firebasestorage.googleapis.com/v0/b/portfolio-3dacc.appspot.com/o/CatherineResume03012021.pdf?alt=media&token=83a6e3bd-c4ce-4809-a7c8-de456b56f65a'
						target='_blank'
						rel='noreferrer'
						download
					>
						<i class='fas fa-file-download top'>
							<span>Download PDF</span>{' '}
						</i>
					</a>
				</motion.div>
			</Hide>
			<ResumePages>
				<ResumeImage>
					<img
						src='https://firebasestorage.googleapis.com/v0/b/portfolio-3dacc.appspot.com/o/CatherineResume03012021-1.png?alt=media&token=46c96bf6-a842-4fa1-ad42-0743b7746170'
						alt='resume'
					/>
				</ResumeImage>
				<ResumeImage>
					<img
						src='https://firebasestorage.googleapis.com/v0/b/portfolio-3dacc.appspot.com/o/CatherineResume03012021-2.png?alt=media&token=d6cacf4c-2d6d-4dfb-8148-d6be770d88f2'
						alt='resume'
					/>
				</ResumeImage>
			</ResumePages>
		</ResumeStyle>
	)
}
const ResumeStyle = styled(motion.div)`
	margin-top: 15vh;
    display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
    a {
        text-decoration: none;
        color: white;
		span {
			font-family: 'Roboto', sans-serif;
			padding-left: 8px;
		}
        i {
            font-size: 1.5rem;
            padding-bottom: 15px;
            color: #0B4C5F;
        }
        i:hover {
            color: #E9FA03;
        }
		.top {
				padding-bottom: 8px;
		}
    }
`
const ResumePages = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 40px;
	@media (min-width: 1190px) {
		flex-direction: row;
	}
`
const ResumeImage = styled(Image)`
	display: flex;
	align-self: center;
	max-width: 80%;
	justify-content: center;
	img {
		margin-bottom: 10px;
		margin-top: 10px;
	}
	@media (min-width: 1190px) {
		max-width: 40%;
		margin-left: 10px;
		margin-right: 10px;
		padding: 0px;
	}
`

const Hide = styled.div`overflow: hidden;`

export default ResumeScreen
