import React from 'react'
import styled from 'styled-components'

//Page Components
import AboutSection from '../components/AboutSection'
import FaqSection from '../components/FaqSection'
//Animations
import { motion } from 'framer-motion'
import { pageAnimation } from '../animation'
import ScrollTop from '../components/ScrollTop'

const AboutScreen = () => {
	return (
		<AboutStyle
			exit='exit'
			variants={pageAnimation}
			initial='hidden'
			animate='show'
		>
			<AboutSection />
			<FaqSection />
			<ScrollTop />
		</AboutStyle>
	)
}

export default AboutScreen

const AboutStyle = styled(motion.div)`
	overflow: hidden;
`
